<template>
  <div v-if="$store.getters.ready">
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 v-if="$route.params.group" class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${$route.params.group}`" class="no-link">{{ form.name }}</router-link></div> Settings</h5>
              <h5 v-else class="page-title-heading mt-1 py-2">New Group</h5>
            </div>
          </div>
          <div v-if="!$route.params.group" class="mb-3"></div>

          <div class="widget-list">
            <form class="form-horizontal" @submit.prevent="send">

              <div class="row">
                <div class="col-md-3 pr-3 d-none d-md-block" v-if="!$route.params.group">
                  <p>Groups contain a collection projects for a single client. Each client should have one or more groups.</p>
                  <p>Multiple clients should <strong>never</strong> share the same group.</p>
                </div>

                <div class="col">
                  <div class="widget-holder">
                    <div class="widget-bg">
                      <div class="widget-body">

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Group Name</label>
                          <div class="col-md-10">
                            <input type="text" v-model="form.name" class="form-control" maxlength="100" placeholder="My Group" required />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label">Group URL</label>
                          <div class="col-md-10">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">https://{{ $config.domain }}/</span>
                              </div>
                              <input type="text" v-model="form.path" class="form-control" maxlength="100" placeholder="my-group" required />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div v-if="$route.params.group" class="row">
                    <div class="col-6"><button type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-check mr-2"></i>Save Changes</button></div>
                    <div class="col-6 text-right"><button type="button" class="btn text-danger my-3" v-b-modal.deletemodal><i class="fal fa-times mr-2"></i>Delete Group</button></div>
                  </div>
                  <button v-else type="submit" class="btn btn-primary btn-rounded my-3"><i class="fal fa-plus mr-2"></i>Create Group</button>
                </div>
              </div>

            </form>
          </div>

          <b-modal id="deletemodal" title="Delete Group?" centered>
            This group will be deleted
            <template v-slot:modal-footer="{ cancel }">
              <button type="button" class="btn btn-danger btn-rounded mr-2" @click="deleteGroup()">Delete</button>
              <button type="button" class="btn btn-default btn-rounded mr-auto" @click="cancel()">Cancel</button>
            </template>
          </b-modal>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);

export default {
  data() {
    return {
      form: {
        path: '',
        name: '',
      },
    };
  },
  methods: {
    send() {
      this.form.path = this.form.path.toLowerCase();

      let request;
      if (this.$route.params.group) {
        request = this.$api.put(`/groups/${this.$route.params.group}`, this.form);
      } else {
        request = this.$api.post('/groups', this.form);
      }
      request
        .then(() => {
          if (!this.$route.params.group) {
            this.$router.push('/projects/new');
          } else {
            toast.icon(this, 'success', 'fa-check');
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteGroup() {
      this.$api.delete(`/groups/${this.$route.params.group}`)
        .then(() => {
          this.$cache.api.remove('/groups');
          this.$cache.api.remove(`/groups/${this.$route.params.group}`);
          this.$router.push('/dashboard');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  mounted() {
    if (!this.$auth.profile.site_admin) {
      this.$store.commit('error', 404);
      return;
    }
    if (this.$route.params.group) {
      this.$api.get(`/groups/${this.$route.params.group}`)
        .then((res) => {
          this.form.path = res.data.path;
          this.form.name = res.data.name;
          document.title = `${this.form.name} - ${this.$route.meta.title} - ${this.$config.pretty_name}`;
          this.$store.commit('ready', true);
        })
        .catch((e) => {
          this.$store.commit('error', e);
        });
    } else {
      this.$store.commit('ready', true);
    }
  },
  components: {
    Header,
  },
};
</script>
